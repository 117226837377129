import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import {
  UiEmbed, Link, PriceRange, WysiwygStyleWrapper,
  ImageAttribution,
  HtmlContentLoader,
} from '@powdr/components';
import {
  BlockTypes,
  DorPlaceStatus,
  ThemeProps as Prop,
  StaticProfiles,
} from '@powdr/constants';
import { ContentBlockType } from '@powdr/model';
import {
  themeUtil, formatDate, phoneNumberPrettify,
  getDorStatusColor,
  capitalizeAllWords,
} from '@powdr/utils';

const CPNT = BlockTypes.BASIC;

// styled components
const ContentBlockBasic = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ $verticalAlignment }) => ($verticalAlignment) && `justify-content: ${$verticalAlignment}`};

  .link-icon {
    margin-left: 5px;
    margin-top: -3px;
    height: 1em;
    width: 1em;

    svg {
      fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
    }
  }

  .image-container {
    position: relative;
  }

  .cb-title {
    margin-bottom: 15px;
  }

  .cb-copy {
    margin-bottom: 15px;
  }

  .cb-basic-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};
  }

  &:hover {
    .cb-basic-button {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }

    .link-icon {
      svg {
        fill: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
        color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
      }
    }
  }

  small:not(.template) {
    margin-bottom: 10px;
  }
`;

const TopContainer = styled.div``;

const ImageContainer = styled(Link)`
  display: grid;
  margin-bottom: 15px;
`;

const StyledImage = styled(GatsbyImage)`
  grid-area: 1/1;
`;

const Image = styled.img`
  grid-area: 1/1;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 50px;
  margin-bottom: 10px;
`;

const AdditionalInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 50px;
  line-height: 1.2;
  padding: 30px 0;

  a {
    transition: color .25s ease-out;
    color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT, CPNT, colorProfile)};

    &:hover {
      color: ${({ colorProfile }) => themeUtil(Prop.LINK_TXT_HOVER, CPNT, colorProfile)};
    }
  }
`;

const AILeft = styled.div``;

const CustomPriceRange = styled(PriceRange)`
  font-weight: bold;
`;

const AIRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
`;

const AIRightTop = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
`;

const AIRightBottom = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusHeader = styled.small``;

const StatusValue = styled.small`
  font-weight: normal;
  color: ${({ $status }) => getDorStatusColor(
    DorPlaceStatus,
    $status,
    CPNT,
    StaticProfiles.ALERTS,
  )} !important;
`;

const Date = styled.p`
  text-align: left;
  line-height: 1.1;
  font-weight: bold;
`;

const Tags = styled.p`
  text-align: right;
  font-weight: bold;
  line-height: 1.1;
  text-transform: capitalize;
`;

export const Basic = ({
  title, copy, blockType, colorProfile, image, videoId, isStaticImageLink, ariaLabel,
  buttonEnabled, buttonLink, buttonText, buttonTarget, buttonClick, date, endDate,
  altButtonEnabled, altButtonText, altButtonLink, altButtonTarget,
  formattedContent, tags, status, verticalAlignment,
  hours, phoneNumber, phoneNumberDetails, location, locationDetails, priceRange,
}) => (
  <ContentBlockBasic
    colorProfile={colorProfile}
    className={`content-block-${blockType}`}
    $verticalAlignment={verticalAlignment}
  >
    <TopContainer>
      {(date || tags) && (
      <TagContainer>
        {(date) && <Date>{`${formatDate(date).month.num}/${formatDate(date).day.num}/${formatDate(date).year}${(endDate && date !== endDate) ? ` - ${formatDate(endDate).month.num}/${formatDate(endDate).day.num}/${formatDate(endDate).year}` : ''}`}</Date>}
        {(tags) && <Tags>{tags}</Tags>}
      </TagContainer>
      )}
      {(image) && (
      <ImageContainer
        onClick={() => buttonClick()}
        aria-label={ariaLabel}
        role="button"
        href={buttonLink}
        target={buttonTarget}
      >
        {(!videoId && !isStaticImageLink) && <StyledImage image={image.src} alt={image?.altText} />}
        {(!videoId && isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
        {(!videoId) && <ImageAttribution>{image?.attribution}</ImageAttribution>}
        {(videoId) && (
          <div className="image-container">
            <UiEmbed videoId={videoId} />
            {(!isStaticImageLink) && <StyledImage image={image.src} alt={image?.altText} />}
            {(isStaticImageLink) && <Image src={image.src} alt={image?.altText} />}
            <ImageAttribution>{image?.attribution}</ImageAttribution>
          </div>
        )}
      </ImageContainer>
      )}
      {(title) && <h3 className="cb-title">{title}</h3>}
      {(hours || phoneNumber || location || status) && (
      <AdditionalInfoContainer $colorProfile={colorProfile}>
        <AILeft>
          {(priceRange?.length > 0) && <CustomPriceRange max={4} value={priceRange.length || 1} character="$" />}
          <div>{ReactHtmlParser(hours)}</div>
        </AILeft>
        <AIRight>
          {(status) && (
            <AIRightTop>
              <StatusHeader className="template">Status</StatusHeader>
              <StatusValue className="template" $status={status}>{capitalizeAllWords(status)}</StatusValue>
            </AIRightTop>
          )}
          {(location || locationDetails) && (
            <AIRightTop>
              {(location) && <small className="template">{location}</small>}
              {(locationDetails) && <small className="template">{locationDetails}</small>}
            </AIRightTop>
          )}
          {(phoneNumber || phoneNumberDetails) && (
          <AIRightBottom>
            {(phoneNumber) && <small className="template"><a href={`tel:${phoneNumber}`}>{phoneNumberPrettify(phoneNumber)}</a></small>}
            {(phoneNumberDetails) && <small className="template">{phoneNumberDetails}</small>}
          </AIRightBottom>
          )}
        </AIRight>
      </AdditionalInfoContainer>
      )}
      {(copy) && <p className="cb-copy">{ReactHtmlParser(copy)}</p>}
      {(formattedContent) && <WysiwygStyleWrapper className="cb-formatted-content"><HtmlContentLoader html={formattedContent} allowRerender /></WysiwygStyleWrapper>}
    </TopContainer>
    <ButtonWrapper>
      {(buttonText && buttonEnabled) && (
        <Link
          className="button with-background"
          onClick={() => buttonClick()}
          aria-label={ariaLabel}
          role="button"
          href={buttonLink}
          target={buttonTarget}
        >
          {buttonText}
        </Link>
      )}
      {(altButtonEnabled) && (
        <Link
          className="button with-background"
          onClick={() => buttonClick()}
          aria-label={ariaLabel}
          role="button"
          href={altButtonLink}
          target={altButtonTarget || buttonTarget}
        >
          {altButtonText}
        </Link>
      )}
    </ButtonWrapper>
  </ContentBlockBasic>
);

Basic.propTypes = PropTypes.objectOf(ContentBlockType).isRequired;
