/* eslint-disable no-lonely-if */
import styled from 'styled-components';

import { Icon } from '@powdr/components/icon';

export const StyledFeatureItem = styled.div``;

export const ItemAlignmentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 12px;
`;

export const ItemIcon = styled(Icon)`

  svg {
    fill: ${({ iconColor }) => iconColor};
    color: ${({ iconColor }) => iconColor};
  }
`;

export const ItemText = styled.div`
  line-height: 1.1;
  max-width: 200px;
  padding-left: 10px;
  padding-right: 4px;
`;

export const InlineCopyIcon = styled(Icon)`
  display: inline-block;
  height: 100%;
  padding-left: 5px;

  svg {
    fill: ${({ iconColor }) => iconColor};
    color: ${({ iconColor }) => iconColor};
  }
`;
