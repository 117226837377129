import React, { useEffect } from 'react';

import { useStaticData } from '@powdr/web/src/hooks';

import { StyledParallaxScroll } from './styles';

export const ParallaxScroll = () => {
  const { property } = useStaticData();

  const parallaxController = () => {
    const image1 = document.querySelector('.parallax-image-1');
    const image2 = document.querySelector('.parallax-image-2');
    const scrollPosition = window.scrollY; // Current scroll position
    // Control the movement speeds
    const verticalSpeed = 1.4; // Speed factor for vertical movement
    const horizontalSpeed1 = 0.75; // Speed factor for image1 horizontal movement
    const horizontalSpeed2 = 0.75; // Speed factor for image2 horizontal movement

    // Define the length of the parallax "loop" (adjustable)
    const loopPoint = 3300; // The point at which the parallax effect resets (in pixels)
    // Calculate the adjusted scroll position for looping effect
    const loopedScrollPosition = scrollPosition % loopPoint; // This creates a looping effect
    // Move the first image diagonally (top-left to bottom-right)
    const image1X = loopedScrollPosition * horizontalSpeed1; // Horizontal movement for image1
    const image1Y = loopedScrollPosition * verticalSpeed; // Vertical movement for image1
    image1.style.transform = `translate(${image1X}px, ${image1Y}px)`;
    // Parallax Image 2 - starts 500 pixels down the page
    const startAt = 500; // Image 2's parallax starts after 500px scroll
    if (loopedScrollPosition > startAt) {
      const image2X = (loopedScrollPosition - startAt) * horizontalSpeed2; // Horizontal movement for image2
      const image2Y = (loopedScrollPosition - startAt) * verticalSpeed; // Vertical movement for image2
      image2.style.transform = `translate(-${image2X}px, ${image2Y}px)`; // Move image2 diagonally
    } else {
    // If scroll is less than 500px, reset image2 to its original position
      image2.style.transform = 'translate(0px, 0px)';
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', parallaxController);

    return () => {
      window.removeEventListener('scroll', parallaxController);
    };
  }, []);

  return (
    <StyledParallaxScroll>
      <div className="parallax-image-1" id="parallax1">
        <img
          className="parallax-image"
          src={`/assets/${property}/parallax-images/blog_parallaxing_red_tram_3378x2326.png`}
          alt=""
        />
      </div>
      <div className="parallax-image-2" id="parallax2">
        <img
          className="parallax-image"
          src={`/assets/${property}/parallax-images/blog_parallaxing_reverse_blue_tram_3378x2326.png`}
          alt=""
        />
      </div>
    </StyledParallaxScroll>
  );
};

ParallaxScroll.propTypes = {
};

ParallaxScroll.defaultProps = {
};
