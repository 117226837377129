/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { Alignment } from '@powdr/constants';

export const StyledUiForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: ${({ $alignment }) => (
    ($alignment === Alignment.LEFT)
      ? 'flex-start'
      : ($alignment === Alignment.RIGHT)
        ? 'flex-end'
        : 'center'
  )};
`;
